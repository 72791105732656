<p-dialog
  (onShow)="prepareForEdit()"
  [(visible)]="prompt"
  [blockScroll]="true"
  [maximizable]="true"
  [modal]="true"
  [style]="{'min-width':'600px'}"
  showEffect="fade"
  appendTo="body"
  [formGroup]="form"
  (onHide)="close()"
>
  <p-header>
    <div
      *ngIf="errorMessage"
      class="row"
      class="alert alert-danger"
      role="alert"
      [innerHTML]="errorMessage"
    ></div>
    {{ actionLabel| translate }}
  </p-header>
  <div>
    <div class="row" *ngIf="onlyAttachments">
      <div class="is-attachment">
        <span style="background: yellow">
          **{{ "CanOnlyPerformAttachments" | translate }}**
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label>{{ "Action" | translate }}: </label>
        <textarea id="actionTxt"
          name="action"
          type="text"
          class="form-control"
          formControlName="Action"
          [readonly]="!canEditAction"
        >
        </textarea>
      </div>
    </div>
    <div class="row" *ngIf="!canTranslate">
      <div class="col-md-12">
        <app-files-form
          formControlName="Links"
          [canUploadFiles]="canAttach"
          [filesList]="pssrFiles"
        >
        </app-files-form>
      </div>
    </div>

    <ng-container *ngIf="!canTranslate">
      <div class="row">
        <div class="col-md-12">
          <span *ngIf="!canEditPriority">
            <label>{{ "Priority" | translate }}:&nbsp;&nbsp;</label>
            <span
              [ngClass]="{
                p3Class: form.controls.Priority?.value == 3,
                p4Class: form.controls.Priority?.value == 4,
                p5Class: form.controls.Priority?.value == 5
              }"
            >
              &nbsp;&nbsp;
              {{
                form.controls.Priority?.value === 3
                  ? selectedBU?.P3Label
                  : form.controls.Priority?.value === 4
                  ? selectedBU?.P4Label
                  : form.controls.Priority?.value === 5
                  ? selectedBU.P5Label
                  : ""
              }}
              &nbsp;&nbsp;
            </span>
          </span>
          <br *ngIf="!canEditPriority" />
          <div *ngIf="canEditPriority" class="radio-toolbar">
            <strong>{{ "Priority" | translate }}:</strong>
            <ng-container *ngIf="canAddP3">
              <input
                type="radio"
                id="P3"
                [value]="3"
                formControlName="Priority"
              />
              <label for="P3" style="margin-bottom: 0px">
                <span
                  *ngIf="form.controls.Priority?.value == 3"
                  class="p3Class"
                >
                  &nbsp;&nbsp;{{ selectedBU?.P3Label }}&nbsp;&nbsp;</span
                >
                <span *ngIf="form.controls.Priority?.value != 3" class="">
                  &nbsp;&nbsp;{{ selectedBU?.P3Label }}&nbsp;&nbsp;</span
                >
              </label>
            </ng-container>

            <ng-container *ngIf="pssr.Status &lt; 7">
              <input
                type="radio"
                id="P4"
                [value]="4"
                formControlName="Priority"
              />
              <label for="P4" style="margin-bottom: 0px">
                <span
                  *ngIf="form.controls.Priority?.value == 4"
                  class="p4Class"
                >
                  &nbsp;&nbsp;{{ selectedBU?.P4Label }}&nbsp;&nbsp;</span
                >
                <span *ngIf="form.controls.Priority?.value != 4" class="">
                  &nbsp;&nbsp;{{ selectedBU?.P4Label }}&nbsp;&nbsp;</span
                >
              </label>
            </ng-container>

            <ng-container *ngIf="selectedBU?.ShowP5">
              <input
                type="radio"
                id="P5"
                [value]="5"
                formControlName="Priority"
              />
              <label for="P5" style="margin-bottom: 0px">
                <span *ngIf="form.controls.Priority?.value == 5" class="p5Class"
                  >&nbsp;&nbsp;{{ selectedBU.P5Label }}&nbsp;&nbsp;</span
                >
                <span *ngIf="form.controls.Priority?.value != 5" class=""
                  >&nbsp;&nbsp;{{ selectedBU.P5Label }}&nbsp;&nbsp;</span
                >
              </label>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="canAssignTo" class="col-md-12">
          <label>{{ "Assign to" | translate }}:</label>
          <div class="">
            <div style="margin-right: 10px; display: inline">
              <input
                type="radio"
                id="PSSRMembers"
                [value]="1"
                [formControl]="assignToForm.controls.assignToType"
              />
              <label for="PSSRMembers" style="margin-left: 5px">
                {{ "PSSRMembers" | translate: selectedBU }}
              </label>
            </div>
            <div style="margin-right: 10px; display: inline">
              <input
                type="radio"
                id="CAI"
                [value]="2"
                [formControl]="assignToForm.controls.assignToType"
              />
              <label for="CAI" style="margin-left: 5px">{{
                "ChevronDirectory/SharedUsers" | translate
              }}</label>
            </div>
            <div style="margin-right: 10px; display: inline">
              <ng-container *ngIf="groups?.length > 0">
                <input
                  type="radio"
                  id="Group"
                  [value]="4"
                  [formControl]="assignToForm.controls.assignToType"
                />
                <label for="Group" style="margin-left: 5px">{{
                  "Group" | translate
                }}</label>
              </ng-container>
            </div>

            <div style="margin-right: 10px; display: inline">
              <input
                type="radio"
                id="NonCAI"
                [value]="3"
                [formControl]="assignToForm.controls.assignToType"
              />
              <label for="NonCAI" style="margin-left: 5px">{{
                "Other" | translate
              }}</label>
            </div>

            <div *ngIf="assignToForm.controls.assignToType?.value == 1">
              <select id="selectAssignedUserid" class="form-control" formControlName="AssignedUserID">
                <ng-container>
                  <ng-container *ngFor="let user of pssr.PSSRUsers.Users">
                    <ng-container *ngIf="user.Role !== UserRole.Translator">
                      <option [ngValue]="user.UserID">
                        {{ user.UserName }} ({{ user.UserEmail }})
                      </option>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </select>
            </div>
            <p-autoComplete
              *ngIf="assignToForm.controls.assignToType?.value == 2"
              field="FullName"
              [formControl]="assignToForm.controls.selectedUser"
              [suggestions]="participants"
              (completeMethod)="getParticipants($event)"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              placeholder=""
              [minLength]="1"
              delay="1000"
            >
              <ng-template let-participant pTemplate="item">
                {{ participant | participantType }}
              </ng-template>
            </p-autoComplete>

            <input
              type="text"
              class="form-control"
              formControlName="AssignedText"
              *ngIf="assignToForm.controls.assignToType?.value == 3"
            />

            <div *ngIf="assignToForm.controls.assignToType?.value == 4">
              <select class="form-control" formControlName="AssignedGroupID">
                <option *ngFor="let grp of groups" [ngValue]="grp.Id">
                  {{ grp.Name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="!canAssignTo" class="col-md-12">
          <label>{{ "Assigned to" | translate }}:</label>
          <span>&nbsp;&nbsp;{{ assignedToRO }}</span>
        </div>
      </div>

      <div class="row" *ngIf="selectedBU">
        <div class="col-md-6">
          <ng-container
            *ngIf="selectedBU.Categories && selectedBU.Categories.length > 0"
          >
            <label>{{ "Primary Category" | translate }}:</label>
            <div
              class="primary-category"
              *ngIf="!canChangeCategories"
              [ngStyle]="{
                'font-style': assignToForm.controls.selectedCategory?.value
                  ?.IsActive
                  ? 'normal'
                  : 'italic'
              }"
            >
              {{ assignToForm.controls.selectedCategory?.value?.Name }}
              <span
                *ngIf="
                  assignToForm.controls.selectedCategory?.value &&
                  !assignToForm.controls.selectedCategory?.value?.IsActive
                "
                >(Inactive)</span
              >
            </div>
            <select id="selectPrimaryCategory"
              name="primary-category"
              *ngIf="canChangeCategories"
              class="form-control"
              [formControl]="assignToForm.controls.selectedCategory"
              (change)="
                onCategoryChange(assignToForm.controls.selectedCategory?.value)
              "
            >
              <option [ngValue]="null"></option>
              <ng-container *ngFor="let c of selectedBU.Categories">
                <option *ngIf="c.IsActive" [ngValue]="c">{{ c.Name }}</option>
              </ng-container>
            </select>
          </ng-container>
        </div>
        <div class="col-md-6">
          <ng-container *ngIf="assignToForm.controls.selectedCategory?.value">
            <label>{{ "Secondary Category" | translate }}:</label>
            <span *ngIf="!canChangeCategories">
              {{ assignToForm.controls?.selectedSubCategory?.value?.Name }}
            </span>
            <select id="selectSecondaryCategory"
              *ngIf="canChangeCategories"
              class="form-control"
              [formControl]="assignToForm.controls.selectedSubCategory"
              (change)="
                onSubCategoryChange(
                  assignToForm.controls.selectedSubCategory?.value
                )
              "
            >
              <option [ngValue]=""></option>
              <option
                *ngFor="
                  let sc of assignToForm.controls.selectedCategory?.value
                    .SubCategories
                "
                [ngValue]="sc"
              >
                {{ sc.Name }}
              </option>
            </select>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label>{{ "Status" | translate }}:</label>
          <span *ngIf="!canChangeStatus">&nbsp;&nbsp;{{ statusNameRO }}</span>
          <br />
          <div *ngIf="canChangeStatus">
            <select id="selectStatus"
              class="form-control"
              (change)="onChangeStatus(form.controls.Status?.value)"
              formControlName="Status"
            >
              <option [ngValue]="1">{{ "OpenStatus" | translate }}</option>
              <option [ngValue]="2">{{ "Closed" | translate }}</option>
              <option
                [ngValue]="3"
                style="background-color: #d9534f; color: white !important"
                *ngIf="
                  pssr.CurrentUser.CanEditAction || canAnswer || isSectionLead
                "
              >
                {{ "Cancel" | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="selectedBU?.RequireActionItemDueDate">
          <div class="row">
            <label>{{ "Due Date" | translate }}: </label>
            <br />
            <div *ngIf="canChangeDueDate && canEditAction">
              <p-calendar id="duedate"
                name="duedate"
                [showIcon]="true"
                formControlName="DueDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                yearRange="2010:2030"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: 'calc(100% - 33px)' }"
                appendTo="body"
                [locale]="'PrimeNgCalendarLabels'|translate"
              >
              </p-calendar>
            </div>
            <div *ngIf="!canChangeDueDate || !canEditAction" class="col-xs-7">
              {{ form.controls.DueDate?.value | date: "M/d/y, h:mm:ss a" }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="!onlyAttachments">
      <div class="col-md-12">
        <label>{{ "Comment" | translate }}:</label>
        <textarea id="commentTxt"
          type="text"
          class="form-control"
          formControlName="Comment"
        ></textarea>
      </div>
    </div>

    <ng-container *ngIf="!canTranslate">
      <div
        class="row"
        *ngIf="form.controls.Id?.value &gt; 0 && form.controls.Priority?.value === 3"
      >
        <div class="col-md-12">
          <label>{{ "Rejectionreason" | translate }}:</label>
          <textarea id="rejectReasonTxt"
            type="text"
            class="form-control"
            formControlName="RejectReason"
            readonly
          >
          </textarea>
        </div>
      </div>

      <div class="row" *ngIf="form.controls.Id?.value &gt; 0">
        <div class="col-md-12">
          <label>{{ "Time Captured" | translate }}:</label>
          <span
            >&nbsp;&nbsp;{{
              form.controls.CreateDate?.value | date: "M/d/y, h:mm:ss a"
            }}
          </span>
        </div>
      </div>
      <div class="col-md-12">
        <label>{{ "Created By" | translate }}:</label>
        <span>&nbsp;&nbsp;{{ form.controls.CreatedBy?.value }} </span>
      </div>
      <div class="col-md-12">
        <label>{{ "Updated Date" | translate }}:</label>
        <span
          >&nbsp;&nbsp;{{
            form.controls.UpdatedDate?.value | date: "M/d/y, h:mm:ss a"
          }}
        </span>
      </div>
      <div class="col-md-12">
        <label>{{ "Updated By" | translate }}:</label>
        <span>&nbsp;&nbsp;{{ form.controls.UpdatedBy?.value }} </span>
      </div>
    </ng-container>
  </div>

  <p-footer class="row">
    <button id="aiCancelBtn"
      pButton
      type="button"
      class="ui-button-secondary"
      style="width: 80px; margin-right: 10px; float: right"
      [label]="'Cancel' | translate"
      (click)="close()"
    ></button>
    <button id="aiDeleteBtn"
      pButton
      type="button"
      class="ui-button-danger"
      style="width: 80px; margin-right: 10px; float: right"
      [label]="'Delete' | translate"
      (click)="confirmDeleteAI(form.controls.Id?.value)"
      *ngIf="form.controls.Id?.value &gt; 0 && canDelete && !canTranslate"
    ></button>
    <button id="aiSaveBtn"
      pButton
      class="col-xs-5 whiteSpaceBtn"
      style="width: 80px; float: right"
      type="button"
      [label]="'Save' | translate"
      [disabled]="isDisabledForm$ | async"
      (click)="saveActionItem()"
    ></button>
  </p-footer>
</p-dialog>
